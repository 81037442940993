<template>
  <v-container class="my-5" style="height: 100%">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          width="450px"
          style="margin-left: auto; margin-right: auto" />
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="8" xs="12">
        <validation-observer v-slot="{ handleSubmit, failed }">
          <v-form
            lazy-validation
            @submit.prevent="handleSubmit(submitForm)"
            style="display: flex; flex-direction: column">
            <validation-provider rules="required" v-slot="{ errors }" name="password">
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePassword"
                autofocus
                clearable
                :label="$t('login.password')"
                :error-messages="errors"></v-text-field>
            </validation-provider>
            <input type="hidden" v-model="token" name="token" />
            <v-spacer class="mt-4"></v-spacer>
            <button-submit :failed="failed" :loading="loading" buttonText="buttons.send"></button-submit>
          </v-form>

          <p class="mt-16">
            {{ $t('login.noAccount') }}
            <router-link to="/registration" class="text-decoration-none" style="font-weight: bold; color: blue">
              {{ $t('login.registerHere') }}
            </router-link>
          </p>

          <p class="mt-5">
            {{ $t('registration.haveAccount') }}
            <router-link to="/login" class="text-decoration-none" style="font-weight: bold; color: blue">
              {{ $t('registration.loginHere') }}
            </router-link>
          </p>
        </validation-observer>
      </v-col>
    </v-row>

    <v-dialog v-model="showInvalidTokenDialog" max-width="500px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">{{
          $t('setPassword.already_changed')
        }}</v-card-title>
        <v-spacer />
        <v-card-text style="font-size: 16px; padding-top: 16px">
          {{ $t('setPassword.send_new_reset_request') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="closeInvalidTokenDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import state from '@/store';
import router, { rolesMap } from '@/router';
import store from '@/store';

export default {
  name: 'ForgetPassword',
  components: { ButtonSubmit },
  data: () => ({
    password: '',
    token: '',
    user: null,
    loading: false,
    showInvalidTokenDialog: false,
    showPassword: false,
  }),
  created() {
    this.token = this.$route.query.token;
    if (!this.token) {
      this.$router.push({ path: '/login' });
    }

    const lang = this.$route.query.lang;
    if (lang) {
      this.$store.dispatch('language/setLanguage', lang);
      localStorage.setItem('currentLanguage', lang);
    }
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$store
        .dispatch('auth/verifyUserAndSetPassword', {
          token: this.token,
          password: this.password,
        })
        .then((result) => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.loginUserWithNewPassword(result);
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.showInvalidTokenDialog = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeInvalidTokenDialog() {
      this.showInvalidTokenDialog = false;
    },
    loginUserWithNewPassword(result) {
      this.$store.dispatch('auth/login', { username: result.data.username, password: this.password }).then((res) => {
        window.location.reload();
        if (res.user.role_id !== 3) {
          res.user.role_id = parseInt(res.user.role.id);
          state.dispatch('auth/setUser', res.user);
          state.dispatch('auth/setRole', res.user);
          state.dispatch('auth/setAuthToken', res.access_token);
          state.dispatch('auth/setAuthenticated', true);
          if (res.user.language) {
            localStorage.setItem('currentLanguage', res.user.language);
            this.$store.dispatch('language/changeLanguage', res.user.language);
          }
          state.dispatch('auth/setOrganisationType', res.user.organisation.type);
          res.user.role_id !== 1 && state.dispatch('auth/setSubscription', res.subscription);
          res.user.role_id !== 1 && state.dispatch('auth/setCustomer', res.customer);

          const userRole = rolesMap.find((e) => e.id === parseInt(store.getters['auth/role']));
          if (this.$deviceToken) {
            // eslint-disable-next-line no-console
            this.submitToken(this.$deviceToken).catch(() => console.log('error saving token'));
          }
          router.push({ name: userRole.redirect }).catch((err) => {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              // eslint-disable-next-line no-console
              console.log(err);
            }
          });
          localStorage.setItem('loggedIn', true);
          this.$router.push({ path: '/' });
        }
      });
    },
    async submitToken(token) {
      await this.$store.dispatch('firebaseToken/submitToken', { token });
    },

    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
